import supabase from "@/libs/supabase";
import moment from "moment";

export async function getAllClientList() {
    try {

        const all_clients = await supabase
            .from("client")
            .select("*")
            .order('client_updated_at');

        return all_clients.data;
    } catch (error) {
        console.log('Error in getAllClientList');
        return null;
    }
}

export async function saveClient(form) {

    try {

        const obj = {
            client_firstname: form.client_firstname,
            client_lastname: form.client_lastname,
            client_email: form.client_email,
            client_mobile: form.client_mobile,
            client_is_active: form.client_is_active,
            client_updated_at: moment().format("YYYY-MM-DD HH:mm:ss")
        };

        let client_id = form.client_id;
        if (client_id && !isNaN(client_id)) {
            const { data, error } = await supabase
                .from("client")
                .update(obj)
                .match({ client_id });
            if (error) throw new Error(JSON.stringify(error));
        } else {
            const { data, error } = await supabase
                .from("client")
                .insert(obj);
            if (error) throw new Error(JSON.stringify(error));
            client_id = data[0].client_id;
        }

        // const { client_header_img_file, client_footer_img_file } = form;
        // const update_obj = {};
        // if (client_header_img_file) {
        //     const { data, error } = await supabase
        //         .storage
        //         .from("client-images/header-footer")
        //         .upload(`header-${client_id}-${client_header_img_file.name}`, client_header_img_file, {
        //             upsert: true
        //         });
        //     if (error) throw new Error(JSON.stringify(error));
        //     update_obj.client_header_img = supabase.storage.from("").getPublicUrl(data.Key).data.publicURL;
        // }

        // if (client_footer_img_file) {
        //     const { data, error } = await supabase
        //         .storage
        //         .from("client-images/header-footer")
        //         .upload(`footer-${client_id}-${client_footer_img_file.name}`, client_footer_img_file, {
        //             upsert: true
        //         });
        //     if (error) throw new Error(JSON.stringify(error));
        //     update_obj.client_footer_img = supabase.storage.from("").getPublicUrl(data.Key).data.publicURL;
        // }

        // if (Object.keys(update_obj).length) {
        //     const { data, error } = await supabase.from("client").update(update_obj).match({ client_id })
        //     if (error) throw new Error(JSON.stringify(error));
        // }

        return true;
    } catch (error) {
        console.log('Error in getAllClientList ', error);
        return null;
    }
}

export async function getClientDetails(client_id) {

    try {

        const client_details = await supabase
            .from("client")
            .select("*")
            .match({ client_id });

        return client_details;
    } catch (error) {
        console.log('Error in getClientDetails ', error);
        return null;
    }
}