import supabase from "@/libs/supabase";
import axios from "axios";
import { PDFDocument } from "pdf-lib";
import img from "./Header.jpg";
import white from "./white.png"

export const getAllPdfs = async () => {

    try {

        const all_pdfs = await supabase
            .from("pdfs")
            .select(`
                pdf_name,
                pdf_id,
                client:client_id(client_firstname, client_lastname, client_email),
                pdf_file
            `)
            .order('updated_at', { ascending: false });

        return all_pdfs.data;

    } catch (error) {
        console.log('Error in getting all pdfs ', error);
    }

}

export const savePdf = async (form) => {
    try {
        const { pdf_name, client_id, pdf_file } = form;

        const pdf_buffer = await convertToArrBuffer(pdf_file);

        const img_response = await axios.get(img, { responseType: "arraybuffer" });
        const img_buffer = img_response.data;

        const white_response = await axios.get(white, { responseType: "arraybuffer" });
        const white_buffer = white_response.data;

        const pdf_doc = await PDFDocument.load(pdf_buffer);

        const pdf_image = await pdf_doc.embedJpg(img_buffer);

        const pdf_white = await pdf_doc.embedPng(white_buffer);

        const pages = pdf_doc.getPages();

        for (let i = 0; i < pages.length; i++) {

            const page = pages[i];

            let width = page.getWidth();
            let height = page.getHeight();
            let patch_height = 0;
            if (width > height) {
                patch_height = Math.round(height / 7)
            } else {
                patch_height = height / 10
            }

            page.drawImage(pdf_image, {
                x: 5,
                y: height - 92,
                width: width - 10,
                height: patch_height,
            });

        }

        const last_page = pages[pages.length - 1];

        let width = last_page.getWidth();
        let height = last_page.getHeight();
        let patch_height = 0;
        if (width > height) {
            patch_height = Math.round(height / 6)
        } else {
            patch_height = height / 9
        }

        last_page.drawImage(pdf_white, {
            x: 5,
            y: height - 475,
            width: width,
            height: patch_height,
        });

        const pdfBytes = await pdf_doc.save();

        const fileObj = new File([pdfBytes], 'pdf_file', { type: "application/pdf" })

        const { data: insert_data, error: insert_error } = await supabase
            .from("pdfs")
            .insert({
                pdf_name,
                client_id
            });
        if (insert_error) throw insert_error;

        const pdf_id = insert_data[0].pdf_id;

        const { data: upload_data, error: upload_error } = await supabase
            .storage
            .from("bucket0/client-pdfs")
            .upload(`pdf-${pdf_id}-${pdf_file.name}`, fileObj, {
                upsert: true
            });

        if (upload_error) {
            await supabase.from("pdfs").delete().match({ pdf_id });
            throw upload_error;
        }

        const pdf_file_path = upload_data.Key;
        await supabase.from("pdfs").update({ pdf_file: pdf_file_path }).match({ pdf_id });

        return {
            status: true,
            message: "Pdf Successfully Saved",
            data: null,
        };
    } catch (err) {
        let message = "Error Saving1 pdf";
        if (err.code === "23505") {
            message = "Duplicate Pdf Name"
        }
        return {
            status: false,
            message,
            data: null,
        };
    }
}

export const downloadPdf = async (key) => {
    try {
        const { data, error } = await supabase.storage.from("").download(key);
        if (error) throw error;
        const file_name = key.split("/")[key.split("/").length - 1];
        saveBlobFile(data, file_name);
        return true;
    } catch (error) {
        console.log('Error in downlading pdf ', error);
        return null;
    }
}

export const sendPdfEmail = async (pdf_id) => {
    try {
        const res = await supabase.functions.invoke("sendClientMail", {
            body: JSON.stringify({ pdf_id })
        });
        return true;
    } catch (error) {
        console.log('Error in sendPdfEmail ', error);
        return null;
    }
}

const convertToArrBuffer = (file) => {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        var fileByteArray = [];
        reader.readAsArrayBuffer(file);
        reader.onloadend = function (evt) {
            if (evt.target.readyState == FileReader.DONE) {
                var arrayBuffer = evt.target.result;
                var array = new Uint8Array(arrayBuffer);
                // for (var i = 0; i < array.length; i++) {
                //     fileByteArray.push(array[i]);
                // }
                resolve(arrayBuffer);
            } else {
                reject(new Error('Error'))
            }
        }
        reader.onerror = function (e) { reject(e) }
    });
}

const saveBlobFile = (blob, file_name) => {
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = file_name;
    link.click();
}

// supabase.functions.invoke

// function saveByteArray(byte) {
//     var blob = new Blob([byte], { type: "application/pdf" });
//     var link = document.createElement('a');
//     link.href = window.URL.createObjectURL(blob);
//     var fileName = "pdf_file.pdf";
//     link.download = fileName;
//     link.click();
// };