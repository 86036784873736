<template>
  <div>
    <b-card>
      <div class="btn1 mb-1">
        <div class="search">
          <b-input-group class="input-merge mt-">
            <b-form-input placeholder="Search" v-model="search" />
          </b-input-group>
          <b-button v-b-modal.pdf-form-modal class="mx-2 px-3">
            Add&nbsp;PDF
          </b-button>
        </div>
        <b-modal
          id="pdf-form-modal"
          title="Add PDF"
          ref="pdf-modal-ref"
          hide-footer
        >
          <validation-observer ref="pdfFormRules">
            <b-form>
              <div class="mt-2 mb-1">
                <h2>Pdf Name</h2>
                <validation-provider
                  #default="{ errors }"
                  name="PDF Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="form.pdf_name"
                    id="pdf-name"
                    placeholder="PDF Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div>
                <h2>Choose File To Upload..</h2>
                <validation-provider
                  #default="{ errors }"
                  name="PDF file"
                  rules="required"
                >
                  <b-form-file
                    v-model="form.pdf_file"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    accept="application/pdf, .pdf"
                    no-drop
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div class="mt-2 mb-1">
                <h2>Select Client..</h2>
                <validation-provider
                  #default="{ errors }"
                  name="Client"
                  rules="required"
                >
                  <b-form-select class="mt-0 mb-1" v-model="form.client_id">
                    <b-form-select-option
                      v-for="(item, index) in client_list"
                      :key="'client_' + index"
                      :value="item.client_id"
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ item.client_firstname + " " + item.client_lastname }}
                    </b-form-select-option>
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div>
                <b-button variant="danger" @click="cancel()"> Cancel </b-button>
                <b-button
                  style="margin-left: 1%"
                  type="submit"
                  variant="success"
                  @click.prevent="SavePDF"
                >
                  OK
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-modal>
      </div>

      <b-table
        small
        :fields="fields"
        :items="pdfs_list"
        responsive="sm"
        :per-page="pagination.perPage"
        :current-page="pagination.currentPage"
      >
        <template #cell(client_name)="data">
          {{
            data.item.client.client_firstname +
            " " +
            data.item.client.client_lastname
          }}
        </template>

        <template #cell(client_email)="data">
          {{ data.item.client.client_email }}
        </template>
        <template #cell(actions)="data">
          <div class="icons">
            <div class="h2 mb-0">
              <feather-icon
                class="edit"
                icon="EyeIcon"
                @click="downloadPdf(data.item.pdf_file)"
              />
            </div>
            <div class="h2 mb-0 ml-1 mr-1">
              <feather-icon
                class="edit"
                icon="SendIcon"
                @click="sendPdfEmailToClient(data.item.pdf_id)"
              />
            </div>
          </div>
        </template>
      </b-table>
      <div class="demo-spacing-0">
        <b-pagination
          v-model="pagination.currentPage"
          :total-rows="pdfs_list.length"
          :per-page="pagination.perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import {
  BCard,
  BCardText,
  BLink,
  BTable,
  BProgress,
  BBadge,
  BButton,
  BSidebar,
  VBToggle,
  BPagination,
  BSpinner,
  BFormFile,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BFormSelectOption,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  BCol,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { getAllClientList } from "@/views/pages/Client/useClient";
import { downloadPdf, getAllPdfs, savePdf, sendPdfEmail } from "./usePdf";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BPagination,
    BSpinner,
    BRow,
    BCardText,
    BLink,
    BTable,
    BProgress,
    BBadge,
    BTable,
    BButton,
    BSidebar,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BFormSelect,
    BFormSelectOption,
    BCardText,
    BLink,
    BFormFile,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },

  data() {
    return {
      search: "",
      required,
      selected: null,
      pdf_action: "Add",
      form: {
        pdf_name: null,
        client_id: null,
        pdf_file: null,
      },
      client_list: [],
      fields: [
        { key: "pdf_name", label: "Pdf Name" },
        { key: "client_name", label: "Client Name" },
        { key: "client_email", label: "Client Email" },
        { key: "actions", label: "Actions" },
      ],
      all_pdfs: [],
      pagination: {
        currentPage: 1,
        perPage: 10,
      },
    };
  },
  computed: {
    pdfs_list: function () {
      let arr = this.all_pdfs;
      if (this.search) {
        let search = this.search.toString().toLowerCase();
        arr = arr.filter((val) => {
          let client_name = (
            val.client.client_firstname +
            " " +
            val.client.client_lastname
          ).toLowerCase();
          let email = val.client.client_email.toLowerCase();
          let pdf_name = val.pdf_name.toLowerCase();
          if (
            client_name.includes(search) ||
            email.includes(search) ||
            pdf_name.includes(search)
          )
            return true;
          return false;
        });
      }
      return arr;
    },
  },
  methods: {
    showModal() {
      this.form = { client_id: null, pdf_file: null, pdf_name: null };
      this.$refs["pdf-modal-ref"].show();
    },
    hideModal() {
      this.$refs["pdf-modal-ref"].hide();
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          alert("form submitted!");
        }
      });
    },
    async SavePDF() {
      this.$refs["pdfFormRules"].validate().then(async (success) => {
        if (success) {
          const res = await savePdf(this.form);
          if (res.status) {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Pdf Saved",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            getAllPdfs().then((val) => {
              this.all_pdfs = val;
            });
            this.hideModal();
          } else {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: res.message || "Pdf Not Saved",
                icon: "X",
                variant: "failure",
              },
            });
          }
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Please upload pdf and select client",
              icon: "X",
              variant: "failure",
            },
          });
        }
      });
    },
    async downloadPdf(file_link) {
      const downloaded = await downloadPdf(file_link);
      if (downloaded) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Pdf Downloaded",
            icon: "CheckIcon",
            variant: "Success",
          },
        });
      } else {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "cannot Pdf downloaded",
            icon: "X",
            variant: "failure",
          },
        });
      }
    },
    async sendPdfEmailToClient(pdf_id) {
      // console.log({ pdf_id });
      const sent = await sendPdfEmail(pdf_id);
      if (sent) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Pdf Sent to client mail",
            icon: "CheckIcon",
            variant: "success",
          },
        });
      } else {
      }
    },
  },
  beforeMount() {
    getAllClientList().then((val) => {
      this.client_list = val;
    });
    getAllPdfs().then((val) => {
      this.all_pdfs = val;
    });
  },
};
</script>

<style>
.btn1 {
  text-align: end;
}
.search {
  display: flex;
  width: 70%;
  margin-left: auto;
}
.nameid {
  text-align: start;
}
.edit {
  cursor: pointer;
}
.icons {
  display: flex;
}
</style>